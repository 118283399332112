import React, { useEffect, useState } from 'react';
import Home from '../components/home';
import Aboutme from '../components/aboutme';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Section from '../components/section';
import Seo from '../components/seo';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { PortfolioContext } from '../providers/portfolioContext';
import Certifications from '../components/certifications'
import Experience from '../components/experience'

const firebaseConfig = {
	apiKey: 'AIzaSyAjdq1sKOaU3OwMt3hm--9Ao1m4DUUVw3Y',
	authDomain: 'juanoes-portfolio.firebaseapp.com',
	projectId: 'juanoes-portfolio',
	storageBucket: 'juanoes-portfolio.appspot.com',
	messagingSenderId: '7505879463',
	appId: '1:7505879463:web:4a1818f57cc90f2d825682',
	measurementId: 'G-7EDE33X1NX',
};

const IndexPage = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState('en');
	const changeLanguage = () => {
    if(lang === 'en'){
      setLang('es')
      i18n.changeLanguage('es')
    } else {
			setLang('en');
      i18n.changeLanguage('en')
		}
	};
	useEffect(() => {
		const firebase_app = import('firebase/app');
		const analytics = import('firebase/analytics');
		// Initialize Firebase
		Promise.all([firebase_app, analytics]).then(([firebase, info]) => {
			const app = firebase.initializeApp(firebaseConfig);
			info.getAnalytics(app);
		});

    if(navigator.language && navigator.language.startsWith('es')){
      setLang('es');
      i18n.changeLanguage('es')
    }
	}, []);
	
	const [dark, setDark] = useState(true);
	const toggleDark = () => {
		if (dark) {
			document.documentElement.classList.remove('dark');
			localStorage.theme = 'light';
			setDark(false);
		} else {
			localStorage.theme = 'dark';
			document.documentElement.classList.add('dark');
			setDark(true);
		}
	};
	useEffect(() => {
		if (localStorage.theme === 'dark') {
			document.documentElement.classList.add('dark');
			setDark(true);
		} else {
			document.documentElement.classList.remove('dark');
			setDark(false);
		}
	}, []);
	
	return (
		<PortfolioContext.Provider value={{ isDark: dark, toggleDark, lang, changeLanguage, }}>
			<Layout>
				<Seo title="Juan Olvera" />
				<Nav />
				<Home />
				<Aboutme />
				<Experience/>
				<Certifications/>
				{/* <StaticImage
        src="../images/gatsby-astronaut.png"
        width={300}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="A Gatsby astronaut"
        style={{ marginBottom: `1.45rem` }}
      />
      <p>
        <Link to="/page-2/">Go to page 2</Link> <br />
        <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
        <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
        <Link to="/using-dsg">Go to "Using DSG"</Link>
      </p> */}
			</Layout>
		</PortfolioContext.Provider>
	);
};

export default IndexPage;
