import React, { useContext } from 'react';
import Section from './section';
import Wave2 from '../images/Wave2.svg';
import DarkWave2 from '../images/DarkWave2.svg';
import { PortfolioContext } from '../providers/portfolioContext';
import { useTranslation } from 'react-i18next';

const Aboutme = () => {
	const { isDark, lang } = useContext(PortfolioContext);
	const { t } = useTranslation();

	return (
		<Section
			id="aboutme"
			style={{
				backgroundImage: `url(${isDark ? DarkWave2 : Wave2})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className="flex flex-wrap gap-4 justify-evenly">
				<div className="w-10/12 md:w-5/12 lg:w-4/12">
					<span className="hint"></span>
					<p className="title">{t('aboutme.profileTitle')}</p>
					<p className="mb-6">
						{t('aboutme.desc1')} 
						<span className="hint">{' '}APIs REST</span>,
						<span className="hint">{' '}MVC</span>,
						<span className="hint">{' '}MVT</span>,
						<span className="hint">{' '}SPAs</span>,
						<span className="hint">{' '}Responsive Design{' '}</span>
						{t('aboutme.desc2')}
						<span className="hint">{' '}(UX)</span>.
					</p>
					<p className="mb-6">
					{lang !== 'es' ? 'I use ' : 'Utilizo '}
					<span className="hint">react.js{' '}</span>
					{t('aboutme.desc3')}
					<span className="hint">{' '}tailwindcss</span>.
					</p>
				</div>
				<div className="w-10/12 md:w-5/12 lg:w-4/12">
					<p className="title">{t('aboutme.educationTitle')}</p>
					<p className="mb-6">{t('aboutme.education')}</p>
					<p className="title">{t('aboutme.languagesTitle')}</p>
					<p className="mb-6">{t('aboutme.languages')}</p>
				</div>
			</div>
		</Section>
	);
};

export default Aboutme;
