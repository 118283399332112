import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Section from './section';
import Lines from '../images/Lines.svg';
import Wave from '../images/Wave.svg';
import DarkWave from '../images/DarkWave.svg';
import Decoration from '../images/Decoration.svg';
import BackgroundSVG from './BackgroundSVG';
import { PortfolioContext } from '../providers/portfolioContext';

const Home = () => {
	const { t } = useTranslation();

	const { isDark } = useContext(PortfolioContext);
	return (
		<div
			className="h-screen relative"
			style={{
				backgroundImage: `url(${isDark ? DarkWave : Wave})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			{/* <img src={Wave} alt="" className="absolute bottom-0 right-0 text-white" /> */}
			<BackgroundSVG src={Lines} />
			<Section id="inicio" extraPadding>
				<div className="w-full h-full flex flex-col justify-center z-10 relative">
					<div className="lg:w-8/12">
						<p className="text-2xl md:3xl xl:text-4xl">
							<span className="text-red">
								This is a work in progress, please be patient.
							</span>
							<br />
							<span className="hint">{t('home.greeting')} </span>
							{t('home.myself')} <br /> <span>{t('home.degree')}</span>{' '}
							{t('home.focus')} <span>{t('home.webdev')}</span>
						</p>
						<p className="text-2xl mt-2">
							{t('home.contactme')} <span>juan.olvera.oes@gmail.com</span>
						</p>
					</div>
					<div className="xl:absolute xl:right-0 xl:h-96 xl:w-96 h-12 w-12 mt-4">
						<img src={Decoration} alt="" className="w-full h-full" />
					</div>
				</div>
			</Section>
		</div>
	);
};

export default Home;
