import React from 'react';

const Section = ({ children, extraPadding = true, ...props }) => {
	return (
		<section
			className={`px-4 md:px-6 lg:px-28 min-h-screen w-full ${
				extraPadding ? 'pt-40' : ''
			}`}
			{...props}
		>
			{children}
		</section>
	);
};

export default Section;
