import React, { useState, useEffect, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../hooks/useMedia';
import barra1 from '../images/barra1.svg';
import barra2 from '../images/barra2.svg';
import barra3 from '../images/barra3.svg';
import translate from '../images/translate.svg';
import { PortfolioContext } from '../providers/portfolioContext';
import {
	isWindowOverflowing,
	getScrollbarSize,
	getPaddingRight,
	ownerDocument,
} from '../utils/scroll';
const Nav = () => {
	const { t } = useTranslation();

	const [isActive, setIsActive] = useState(false);
	const [navBackground, setNavBackground] = useState('');
	const { isDark, toggleDark } = useContext(PortfolioContext);
	const handleActive = () => {
		setIsActive((d) => !d);
	};
	const isDesktop = useMediaQuery('(min-width: 640px)');
	useEffect(() => {
		if (isDesktop) {
			setIsActive(false);
		}
	}, [isDesktop]);

	const scrollingBox = useRef(null);
	useEffect(() => {
		let options = {
			root: document.querySelector('#root'),
			rootMargin: '0px',
			threshold: 0,
		};
		const callback = function (entries, observer) {
			const s = entries[0];
			if (s.intersectionRatio === 0) {
				setNavBackground('dark:bg-darkBlue bg-lightDust');
			} else {
				setNavBackground('');
			}
		};
		let observer = new IntersectionObserver(callback, options);
		observer.observe(scrollingBox.current);

		return () => {
			observer.unobserve(scrollingBox.current);
		};
	}, [scrollingBox]);

	return (
		<React.Fragment>
			<div ref={scrollingBox}></div>
			<nav
				className={`fixed top-0 h-20 w-full z-50 px-4 ${navBackground}`}
			>
				<div className="hidden sm:flex content-center justify-center items-center h-full">
					<a className="nav-link" href="#inicio">
						{t('nav.home')}
					</a>
					<a className="nav-link" href="#aboutme">
						{t('nav.aboutme')}
					</a>
					<a className="nav-link" href="#experience">
						{t('nav.experience')}
					</a>
					<a className="nav-link" href="#certifications">
						{t('nav.certifications')}
					</a>
					<DarkToggler dark={isDark} toggleDark={toggleDark} />
					<LanguageToggler />
				</div>
				<div className="flex content-center justify-left items-center h-full sm:hidden">
					<button
						className="h-6 w-8 flex flex-col justify-between"
						aria-label="open menu"
						onClick={handleActive}
					>
						<div>
							<img src={barra1} alt="" />
						</div>
						<div>
							<img src={barra2} alt="" />
						</div>
						<div>
							<img src={barra3} alt="" />
						</div>
					</button>
				</div>
				{isActive && (
					<MobileMenu
						isDark={isDark}
						toggleDark={toggleDark}
						handleActive={handleActive}
						isActive={isActive}
					/>
				)}
			</nav>
		</React.Fragment>
	);
};

export default Nav;

const LanguageToggler = ({}) => {
	const { lang, changeLanguage } = useContext(PortfolioContext);
	return (
		<button
			className="flex px-2 items-center mt-4 sm:mt-0"
			onClick={changeLanguage}
		>
			<span className="dark:text-[#cecece] text-cream capitalize">{lang}</span>
			<span>
				<svg
					className="fill-orange"
					xmlns="http://www.w3.org/2000/svg"
					width="32"
					height="32"
					viewBox="0 0 256 256"
				>
					<rect width="256" height="256" fill="none"></rect>
					<polyline
						points="232 216 176 104 120 216"
						fill="none"
						className="dark:stroke-cream stroke-mutedBlue"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="16"
					></polyline>
					<line
						x1="136"
						y1="184"
						x2="216"
						y2="184"
						fill="none"
						className="dark:stroke-cream stroke-mutedBlue"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="16"
					></line>
					<line
						x1="88"
						y1="32"
						x2="88"
						y2="56"
						fill="none"
						className="dark:stroke-cream stroke-mutedBlue"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="16"
					></line>
					<line
						x1="24"
						y1="56"
						x2="152"
						y2="56"
						fill="none"
						className="dark:stroke-cream stroke-mutedBlue"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="16"
					></line>
					<path
						d="M120,56a96,96,0,0,1-96,96"
						fill="none"
						className="dark:stroke-cream stroke-mutedBlue"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="16"
					></path>
					<path
						d="M61.5,88A96,96,0,0,0,152,152"
						fill="none"
						className="dark:stroke-cream stroke-mutedBlue"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="16"
					></path>
				</svg>
			</span>
		</button>
	);
};
const DarkToggler = ({ dark, toggleDark }) => {
	const { t } = useTranslation();
	return (
		<button
			onClick={toggleDark}
			className={`border border-white sm:border-transparent dark:border-transparent w-24 h-10 rounded-full relative flex justify-end dark:bg-darkBlue bg-lightDust p-1 mt-4 sm:mt-0`}
		>
			<p className="absolute right-11 top-1.5 capitalize text-xl sm:text-base font-normal">
				{dark ? t('nav.dark') : t('nav.light')}
			</p>
			<div
				className={`ml-2 transition-all ease-linear relative bottom-0 rounded-full flex justify-center items-center right-0.5 ${
					!dark ? 'h-8 w-8' : 'h-8 w-8 bg-cream'
				}`}
			>
				<div
					className={`transition-all ease-linear rounded-full flex justify-center items-center ${
						!dark
							? 'h-8 w-8 bg-cream/[.50]'
							: 'h-6 w-6 absolute -top-1 -left-1 dark:bg-darkBlue bg-lightDust'
					}`}
				>
					<div
						className={`transition-all ease-linear rounded-full  ${
							!dark ? 'h-6 w-6 bg-cream' : 'h-1 w-1 rounded-full bg-white'
						}`}
					></div>
				</div>
			</div>
		</button>
	);
};

const MobileMenu = ({ isDark, toggleDark, handleActive, isActive }) => {
	const { t } = useTranslation();
	const ref = useRef(null);
	useEffect(() => {
		let doc = ownerDocument(undefined);
		let { overflow, paddingRight, position } = doc.body.style;
		if (ref.current) {
			const container = ref.current;
			/* istanbul ignore else */
			if (isWindowOverflowing(container)) {
				const scrollbarSize = getScrollbarSize(doc);
				overflow = doc.body.style.overflow;
				paddingRight = doc.body.style.paddingRight;
				doc.body.style.paddingRight = `${
					getPaddingRight(container) + scrollbarSize
				}px`;
				doc.body.style.overflow = 'hidden';
				doc.body.style.position = 'fixed';
			}
		}

		return () => {
			doc.body.style.overflow = overflow;
			doc.body.style.paddingRight = paddingRight;
			doc.body.style.position = position;
		};
	}, [ref, isActive]);

	// isActive ? "translate-y-0" : "-translate-y-full"
	if (isActive) {
		return createPortal(
			<nav
				ref={ref}
				className={`translate-y-0 z-50 fixed inset-0 dark:bg-deepBlue bg-lightDust flex flex-col  px-4 transition-transform`}
			>
				<div className="h-20 flex   items-center">
					<button
						className="h-6 w-8 flex flex-col justify-between"
						aria-label="open menu"
						onClick={handleActive}
					>
						<div className="rotate-45 origin-top-left">
							<img src={barra2} alt="" />
						</div>
						<div className="-rotate-45 origin-bottom-left">
							<img src={barra2} alt="" />
						</div>
					</button>
				</div>
				<a className="nav-link px-0" href="#inicio">
					{t('nav.home')}
				</a>
				<a className="nav-link px-0" href="#aboutme">
					{t('nav.aboutme')}
				</a>
				<a className="nav-link px-0" href="#experience">
					{t('nav.experience')}
				</a>
				<a className="nav-link px-0" href="#certifications">
					{t('nav.certifications')}
				</a>
				<DarkToggler dark={isDark} toggleDark={toggleDark} />
				<LanguageToggler />
			</nav>,
			document.body
		);
	}
	return null;
};
