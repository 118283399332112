import React from 'react';
import Section from './section';

const Certifications = () => {
	return (
		<Section id="certifications">
			<div className="flex flex-wrap gap-8">
				<div className="w-11/12 md:w-5/12">
					<div className="">
						<p className="title">
							Curso Práctico de Django: Aprende creando 3 Webs
						</p>
						<p>ID del certificado: UC-O957ZA3U</p>
						<p>Udemy</p>
						<a href="#" className="certification-link">
							Ver certificado
						</a>
					</div>
				</div>
				<div className="w-11/12 md:w-5/12">
					<div className="">
						<p className="title">
							Introduction to User Experience Principles and Processes
						</p>
						<p>ID del certificado: AVJWJKN9A2UC</p>
						<p>Emite: Coursera</p>
						<a href="#" className="certification-link">
							Ver certificado
						</a>
					</div>
				</div>
				<div className="w-11/12 md:w-5/12">
					<div className="">
						<p className="title">Agile Meets Design Thinking</p>
						<p>ID del certificado: N537P8N8DNMD</p>
						<p>Emite: Coursera</p>
						<a href="#" className="certification-link">
							Ver certificado
						</a>
					</div>
				</div>
				<div className="w-11/12 md:w-5/12">
					<div className="">
						<p className="title">Understanding User Needs</p>
						<p>ID del certificado: P6K3SG2CZG5Q</p>
						<p>Emite: Coursera</p>
						<a href="#" className="certification-link">
							Ver certificado
						</a>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default Certifications;
