import React from 'react';
import Drop from './drop';
import Section from './section';

const Experience = () => {
	return (
		<Section id="experience">
			/
			<div className="flex flex-wrap justify-center">
				<div className="w-11/12 md:w-96">
					<div className="title">Experience</div>
					<p className="mb-4">
						I use <span className="hint">typesript</span> for everything I
						code with <span className="hint">javascript</span>
					</p>
					<p className="mb-4">
						I’m involved in the development of what will be{' '}
						<span className="hint">Frontera-ui</span>, check the
						<a
							href="https://jp-olvera.github.io/jp-viaducto-components"
							className="hint underline"
						>
							{' '}
							storybook{' '}
						</a>
						and the package in
						<a
							href="https://www.npmjs.com/package/@jp-olvera/jp-viaducto-components"
							className="hint underline"
						>
							{' '}
							npm
						</a>
						.
					</p>
					<div>
						<a
							href="https://www.npmjs.com/package/@jp-olvera/jp-viaducto-components"
							target="_blank"
						>
							<img
								src="https://www.logolynx.com/images/logolynx/7f/7fb976a537620fed310872d533cd161c.png"
								alt="npm"
								className="h-20 inline"
							/>
						</a>
						<a
							href="https://jp-olvera.github.io/jp-viaducto-components/"
							target="_blank"
						>
							<img
								src="https://rossta.net/assets/images/blog/webpack/storybook-logo.png"
								alt="storybook"
								className="h-10 inline ml-3 bg-r rounded p-1"
							/>
						</a>
					</div>
				</div>
				<div className="w-11/12 md:3/4 lg:w-2/4">
					<p className="title">Backend development</p>
					<ul className="list-disc">
						<li>
							<p>
								I have experience developing APIs rest with Nest.js and
								express.js.
							</p>
						</li>
						<li>
							<p>
								I used to implement Django for MVTs but due to my latest
								job I've been using javascript mostly.
							</p>
						</li>
						<li>
							<p>
								I know about production and development environments, and
								the integrations we can implement between deployment
								platforms like
								<span className="hint">{' '}Heroku</span>,
								<span className="hint">{' '}DigitalOcean,</span>
								<span className="hint">{' '}Netlify</span> and Github.
							</p>
						</li>
					</ul>
					<p className="title">Frontend development</p>
					<ul className="list-disc">
						<li>
							<p>
								I know about Client Side Rendering
								<span className="hint"> (CSR)</span>, Server Side Rendering{' '}
								<span className="hint"> (SSR)</span>, and Static Site
								Generation
								<span className="hint"> (SSG)</span>, and the tools we can
								use to implement them like Gatsby.js and
								<span className="hint"> Next.js</span>
							</p>
						</li>
						<li>
							<p>
								I really like React.js, I understand the way it works, and
								the paths you can choose to implement it, either you want
								to use it with
								<span className="hint"> webpack</span>, CRA or Parcel.
							</p>
						</li>
						<li>
							<p>
								I've been involved in the development of a react component
								library, so I know about publishing and mantaining npm
								packages, and the implentation of Github Actions to automate
								the publishing flow.
							</p>
						</li>
						<li>
							<p>
								Of course I know about <span className="hint">HTML</span>{' '}
								(semantics as well), <span className="hint">CSS</span> and
								Responsive Design, I use{' '}
								<span className="hint">CSS-in-JS</span> with
								<a
									href="https://styled-components.com/"
									target="_blank"
									className="underline hint"
								>
									{' '}styled-components
								</a>
								, which is very similar to
								<a
									href="https://emotion.sh/docs/@emotion/css"
									className="hover:underline"
								>
									{' '}
									@<span className="hint underline">emotion</span>/css
								</a>
								, I'm familiar with SASS or Less, but I prefer CSS-in-JS
								because is easier to manage unused styles.
							</p>
						</li>
						<li>
							<p>I have experience working with the DOM apis.</p>
						</li>
						<li>
							<p>
								For those who worries about making the web for everyone I
								know about accessibility
								<span className="hint">{' '}(a11y)</span>, internationalization{' '}
								<span className="hint">{' '}(i18n)</span> and I have taken some
								basic courses about User Experience, so I can identify
								the pain points of a user interface.
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="flex flex-wrap gap-10 justify-around md:justify-between lg:justify-start my-20">
				<div className="w-9/12 md:w-48 md:ml-auto">
					<div className="skill-set">
						<p className="title">Main skills</p>
						<div className="skill">
							<p>Typescript</p>
						</div>
						<div className="skill">
							<p>Javascript</p>
						</div>
						<div className="skill">
							<p>React.js</p>
						</div>
						<div className="skill">
							<p>Node.js</p>
						</div>
						<div className="skill">
							<p>Nest.js</p>
						</div>
						<div className="skill">
							<p>MySQL</p>
						</div>
					</div>
				</div>
				<div className="w-9/12 md:w-48">
					<div className="skill-set">
						<p className="title">Familiar with</p>
						<div className="skill">
							<p>Python</p>
						</div>
						<div className="skill">
							<p>Django</p>
						</div>
						<div className="skill">
							<p>Go</p>
						</div>
						<div className="skill">
							<p>PHP</p>
						</div>
						<div className="skill">
							<p>Flutter</p>
						</div>
						<div className="skill">
							<p>Dart</p>
						</div>
					</div>
				</div>
				<div className="w-9/12 md:w-48 md:mr-auto">
					<div className="skill-set">
						<p className="title">Other tools</p>
						<div className="skill">
							<p className='hint'>Git and GitHub</p>
						</div>
						<div className="skill">
							<p>Figma</p>
						</div>
						<div className="skill">
							<p>Insomnia</p>
						</div>
						<div className="skill">
							<p className="hint">Vs Code</p>
						</div>
						<div className="skill">
							<p>Bootstrap</p>
						</div>
						<div className="skill">
							<p>Github Actions</p>
						</div>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default Experience;
