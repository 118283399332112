export const resources = {
	en: {
		translation: {
			nav: {
				home: 'home',
				aboutme: 'about me',
				skills: 'skills',
				experience: 'experience',
				certifications: 'certifications',
				light: 'light',
				dark: 'dark',
			},
			home: {
				greeting: 'Hi,',
				myself: "I'm Juan Olvera a",
				degree: 'Software Engineer',
				focus: 'focused on',
				webdev: 'Web Development',
				contactme: 'Contact me:',
			},
			aboutme: {
				profileTitle: 'profile',
				desc1:
				`'Web Developer with experience in`,
				desc2: `and with knowledge in User Experience`,
				desc3:
					'for most of my web development, and lately I’ve been implementing it with gatsby.js, just this site its powered by react, gatsby and tailwindcss.',
				educationTitle: 'education',
				education:
					'Software Engineering - Universidad Autónoma de Querétaro',
				languagesTitle: 'languages',
				languages: 'English - Intermediate',
			},
			skills: {
				main: 'main skills',
				basic: 'basic knowledge',
				other: 'other tools',
			},
		},
	},
	es: {
		translation: {
			nav: {
				home: 'inicio',
				aboutme: 'sobre mi',
				skills: 'habilidades',
				experience: 'experiencia',
				certifications: 'certificaciones',
				light: 'día',
				dark: 'noche',
			},
			home: {
				greeting: 'Hola,',
				myself: 'Soy Juan Olvera un',
				degree: 'Ingeniero de Software',
				focus: 'centrado en el',
				webdev: 'Desarrollo Web',
				contactme: 'Mi correo:',
			},
			aboutme: {
				profileTitle: 'perfil',
				desc1: 'Desarrollador Web con experiencia en',
				desc2: 'y con conocimientos sobre Experiencia de Usuario',
				desc3: `para la mayoría de mis proyectos, últimamente lo he 
				implementado con gatsby, justo este sitio lo hice con react, gatsby y`,
				educationTitle: 'educación',
				education:
					'Ingeniería de Software - Universidad Autónoma de Querétaro',
				languagesTitle: 'idiomas',
				languages: 'Inglés - Intermedio',
			},
			skills: {
				main: 'habilidades principales',
				basic: 'conocimientos en',
				other: 'otras herramientas',
			},
		},
	},
};
